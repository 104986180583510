.add-more-button.highlight {
    background-color: #17A5E6;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.add-more-button.highlight:hover {
    background-color: #b28c00;
    width: 24px;
    height: 24px;
}