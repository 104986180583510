#login-form .flat-button,
#login-form .flat-textfield {
  box-shadow: none !important;
  filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.05)) !important;
}

#login-form .flat-textfield.error {
  border: 1px solid #f44336 !important;
}

#login-form .login-meta {
  font-size: 13px !important;
}

#login-form .login-meta .MuiFormControlLabel-label {
  font-size: 13px !important;
}

#login-form .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

#login-form .MuiInputBase-input {
  background-color: #fff !important;
  filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.05)) !important;
}
