.MuiChip-outlined.MuiChip-sizeSmall { height: 20px !important; }
.MuiChip-outlined .MuiChip-label.MuiChip-labelSmall {
    padding-left: 6px;
    padding-right: 7px;
    font-size: 11px;
    letter-spacing: 0;
    margin-top: 1px;
    text-transform: lowercase;
}
.MuiChip-outlined .MuiChip-deleteIcon {
    margin-right: 1px !important;
}
.MuiChip-outlined.Mui-disabled {
    opacity: 1 !important;
    color: #0288d1 !important;
}

.MuiChip-outlined.Mui-disabled .MuiChip-deleteIcon {
    display: none;
}