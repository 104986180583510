#chat-field {
    background-color: #FFF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 25px 15px;
    filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.05))";
} 

#chat-field .MuiInputBase-root:after {
    border-bottom-width: 1px;
}

#chat-field .MuiInputBase-root:hover:after {
    border-bottom-width: 2px;
}