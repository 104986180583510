.hints-accordion {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.hints-accordion .hint-chip {
  text-transform: capitalize;
}

.hints-accordion .hint-chip.Mui-disabled {
  opacity: 1;
}

.hints-accordion.competitor-accordion .MuiChip-label {
  text-transform: lowercase;
}

.hints-accordion .hint-header p {
  font-weight: 500;
}

.MuiAccordionSummary-root,
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
  height: 48px !important;
}

.hints-accordion .MuiAccordionSummary-root.locked .MuiAccordionSummary-content {
  cursor: default;
  margin: 0;
}

.MuiDataGrid-row.even {
  background-color: #f5f5f5;
}

.locked .hint-badges,
.Mui-disabled .hint-badges {
  display: none;
}

.status-select {
  height: auto !important;
}
.status-select .MuiSelect-select {
  padding: 0 32px 0 0 !important;
  background: none !important;
}

.hint-buttons-footer {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px) !important;
  height: 40px;
}
