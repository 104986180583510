.hitlist-tabs-container.full-screen {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    z-index: 99999;
    background-color: #fff
}

.hitlist-panel.full-screen {
    position: absolute;
    left: 0;
    top: 49px;
}

.hitlist-tabs-container .enter-full-screen,
.hitlist-tabs-container .exit-full-screen {
    position: absolute;
    top: 8px;
}

.hitlist-tabs-container .exit-full-screen {
    right: 10px;
}

.hitlist-tabs-container .enter-full-screen {
    right: 0;
}

.hitlist-tabs-container .tab-title input:disabled {
    color: #4b4c4c !important;
    -webkit-text-fill-color: #4b4c4c !important;
}