.MuiInputBase-colorPrimary:before {
    border-bottom: 2px solid #FFC800 !important;
}
.MuiInputBase-colorPrimary:after {
    border-bottom: 2px solid #FFC800;
}
#settings-target-country-select .MuiListItemIcon-root {
    position: absolute;
    left: 0;
    top: 8px;
}
#settings-target-country-select .MuiListItemText-root {
    padding-left: 35px;
}