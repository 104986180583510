#assistant {
  height: calc(100vh - 60px);
  overflow-y: auto;
  position: relative;
}

.MuiDialog-paperFullScreen {
  top: 60px;
}

.topic-field .MuiInputBase-input { text-transform: capitalize; }

.assistant-container {
  background-color: #fff;
  align-content: center;
  border-radius: 1px;
}

.Mui-expanded.MuiAccordion-root { height: 100%;}
