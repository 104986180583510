#guide-container,
#guide {
  background-color: #f7f7f7;
}
#guide-chat-window {
  overflow-y: auto;
  height: calc(100vh - 180px);
  position: relative;
}
#guide-buttons {
  background-color: #f7f7f7;
  position: absolute;
  top: -9px;
  margin: 10px;
  cursor: pointer;
  z-index: 100;
  width: 95%;
  padding: 10px 15px 0 0;
  display: flex;
  justify-items: stretch;
  justify-content: space-between;
}
#guide-clear-button {
}
#guide-close-button {
}
