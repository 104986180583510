.hitlist-table {
  height: 100%;
}
.hitlist-table-container {
  border-radius: 0;
  box-shadow: none;
  height: calc(100vh - 282px);
  width: 100%
}
.hitlist-table-container.full-screen {
  position: fixed;
  left: 0;
  height: calc(100vh - 109px);
  z-index: 99999;
  background-color: #fff
}
.hitlist-table-holder {
  height: calc(100vh - 284px);
}
.hitlist-table-holder.full-screen {
  height: calc(100vh - 111px);
}
.hitlist-table .MuiDataGrid-toolbarContainer {
  display: block;
  text-align: left;
  margin-left: 9px;
  margin-bottom: .5em
}
.hitlist-table .MuiDataGrid-toolbarContainer button {
  color: #4b4c4c
}
.hitlist-table .Mui-disabled {
  color: #b6b6b7!important
}
.hitlist-table .MuiDataGrid-row:hover {
  background-color: rgba(255,200,0,.1)!important
}
.MuiDataGrid-detailPanel {
  height: auto!important
}
.hitlist-table .more-details-panel {
  background-color: #fdfdfd;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef
}
.hitlist-table.hitlist-table-empty .MuiDataGrid-columnHeaders,
.hitlist-table.hitlist-table-empty .MuiDataGrid-toolbarContainer {
  display: none
}
.hitlist-table.hitlist-table-empty .MuiDataGrid-columnHeaders .MuiInputBase-input {
  pointer-events: none
}
.hitlist-table.hitlist-table-empty .MuiDataGrid-virtualScroller {
  overflow: hidden
}
.hitlist-table.hitlist-table-empty .MuiDataGrid-main {
  padding-bottom: 20px
}
.MuiDataGrid-filterFormValueInput .MuiInput-root {
  margin-top: 24px!important
}
.MuiDataGrid-columnHeader:focus-within {
  outline: 0!important
}
.MuiDataGrid-withBorderColor:focus,
.MuiDataGrid-withBorderColor:focus-visible,
.MuiDataGrid-withBorderColor:focus-within {
  outline: 0!important
}
.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal!important;
  word-wrap: break-word!important
}