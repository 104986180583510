.global-menu-hitlist-list {
    overflow-y: auto;
    max-height: calc(100vh - 600px);
}
.global-icon {
    background-color: #fff;
}

.global-menu-hitlist-add-button {
    position: absolute !important;
    right: 16px;
}

.global-menu-hitlist-item {
    cursor: pointer;
}

.global-menu-hitlist-item .MuiListItemSecondaryAction-root {
    right: 8px;
}

.global-menu-hitlist-item:hover {
    background-color: #f5f5f5;
}