#header-identity {
  /* margin: -5px 0 -6px -24px; */
  /* padding: 0 30px 0 14px; */
  /* display: flex; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 60px;
}
.MuiAppBar-root {
  box-shadow: none !important;
  border-bottom: 1px solid #e2e2e2;
}
